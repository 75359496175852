<template>
  <div class="shop-auth-shop-assistant">
    <en-table-layout
      :tableData="tableData.data"
      :loading="loading"
    >
      <template slot="toolbar">
        <el-form-item label="活动状态">
          <el-select
            v-model="params.status"
            size="medium"
            style="width: 150px"
            clearable
          >
          <el-option label="全部" value=""></el-option> 
          <el-option label="未开始" value="NOT_STARTED_YET"></el-option>
          <el-option label="进行中" value="IN_PROGRESS"></el-option>
          <el-option label="已关闭" value="CLOSED"></el-option>
          <el-option label="已结束" value="EXPIRED"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="虚拟成团">
          <el-select
            v-model="params.virtual_group"
            size="medium"
            style="width: 150px"
            clearable
          >
          <el-option label="全部" value=""></el-option>
          <el-option label="开启" value="OPEN"></el-option>
          <el-option label="关闭" value="CLOSE"></el-option>
          </el-select>
        </el-form-item>
        <!-- <div class="col"></div> -->
        <el-form-item class="col-auto">
          <el-input style="width: 320px;margin-right:10px" v-model.trim="params.name" size="medium" placeholder="请输入活动名称进行搜索"
            clearable />
            <el-button native-type="submit" @click.stop.prevent="search" size="small" type="primary">搜索</el-button>
            <el-button size="small" type="primary" @click="handleAdd">添加活动</el-button>
        </el-form-item>
      </template>

      <template slot="table-columns">
        <el-table-column prop="name" label="活动名称" show-overflow-tooltip />
        <el-table-column prop="role" label="活动时间" width="300">
          <template slot-scope="{row}">
            {{ row.start_time | secondUnixToDate }}~{{ row.end_time | secondUnixToDate }}
          </template>
        </el-table-column>  
        <el-table-column prop="number" width="80" label="参团人数"/>
        <el-table-column prop="virtual_group" width="80" label="虚拟成团">
          <template slot-scope="{ row }">{{ row.virtual_group=='OPEN' ? '开启' : '关闭' }}</template>
        </el-table-column>
        <el-table-column prop="limited_time"  width="120" label="成团时限(小时)"/>
        <el-table-column key="create_time" label="创建时间" width="160">
          <template slot-scope="scope">
            {{ scope.row.create_time | secondUnixToDate }}
          </template>
        </el-table-column>
        <el-table-column label="活动状态" width="100">
          <template slot-scope="{ row }">{{ row.status | statusFilter}}</template>
        </el-table-column>
        <el-table-column label="操作" width="290" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="handleView(scope.row)">查看
            </el-button>
            <el-button
              v-if="scope.row.status == 'NOT_STARTED_YET'"
              size="mini"
              type="primary"
              @click="handleEdit(scope.row)">修改
            </el-button>
            <el-button
              v-if="scope.row.status == 'NOT_STARTED_YET'"
              size="mini"
              type="primary"
              @click="handleEditShopAssistant(scope.row)">管理
            </el-button>
            <el-button
              v-if="scope.row.status == 'IN_PROGRESS'" 
              size="mini"
              type="danger"
              @click="handleDeleteShopAssistant(scope.$index, scope.row)">关闭
            </el-button>
            <el-button
              v-if="scope.row.status == 'CLOSED'"
              size="mini"
              type="success"
              @click="handleRecoveryShopAssistant(scope.$index, scope.row)">开启
            </el-button>
            <el-button
							size="mini"
							type="danger"
							v-if="scope.row.status != 'IN_PROGRESS'"
							@click="handleDeleteGoods(scope.row)">删除
						</el-button>
          </template>
        </el-table-column>
      </template>

      <el-pagination
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no"
        :page-size="tableData.page_size"
        :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout"
        background
        :total="tableData.data_total">
      </el-pagination>
    </en-table-layout>

    <!--添加-->
    <x-dialog :proxy="examinationDialog">
      <p v-if="!isEdit">提示：1、添加拼团活动后，可到楼层装修处设置移动端进入拼团的活动入口。</p>
      <el-form :model="shopAddAssistantForm" :rules="shopAddistantRules" ref="shopAddAssistantForm" label-width="120px">
        <el-form-item label="活动名称" prop="name">
          <el-input :disabled="isEdit" v-model.trim="shopAddAssistantForm.name" :maxlength="20" clearable placeholder="请输入20字以内的活动名称"></el-input>
        </el-form-item>
        <el-form-item label="生效时间" prop="start_time">
          <el-date-picker
          clearable
          :picker-options="timeOptions"
          :disabled="isEdit"
            v-model="order_time_range"
            type="datetimerange"
            @change="updateTime"
            @focus="getNowTimeTen"
          :default-time="[newDates, '23:59:59']"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="参团人数" prop="number">
          <el-input placeholder="不超3个字符" :disabled="isEdit" v-model="shopAddAssistantForm.number" clearable>
            <template slot="append">人</template>
          </el-input>
        </el-form-item>
        <el-form-item label="成团时限" prop="limited_time">
          <el-input placeholder="不超3个字符" :disabled="isEdit" v-model="shopAddAssistantForm.limited_time" clearable>
            <template slot="append">小时</template>
          </el-input>
        </el-form-item>
        <el-form-item label="虚拟成团" style="margin-bottom:0px">
          <el-switch v-model="shopAddAssistantForm.virtual_group" active-value="OPEN" active-text="开启" :disabled="isEdit" inactive-value="CLOSE" inactive-text="关闭"></el-switch>
        </el-form-item>
        <p style="margin-top:0;margin-left:100px;font-size:12px">开启虚拟成团后，活动成团时限到期时人数未满的团，系统将会模拟匿名买家凑满人数，使该团成团，开启以提高成团率</p>
        <el-form-item label="是否收取运费" style="margin-bottom:0px">
          <el-switch v-model="shopAddAssistantForm.collect_freight" :disabled="isEdit" active-value="OPEN" active-text="收取" inactive-value="CLOSE" inactive-text="不收"></el-switch>
        </el-form-item>
        <p style="margin-top:0;margin-left:100px;font-size:12px">收取运费时，系统将按照商品的拼团价，以及商城后台所设置的运费规则向C端用户收取运费；否则不收取拼团商品运费。</p>
      </el-form>
    </x-dialog>
    <!--查看-->
    <x-dialog :proxy="infoDialog">
      <p v-if="!isEdit">提示：1、添加拼团活动后，可到楼层装修处设置移动端进入拼团的活动入口。</p>
      <el-form :model="shopAddAssistantForm" :rules="shopAddistantRules" ref="shopAddAssistantForm" label-width="120px">
        <el-form-item label="活动名称" prop="name">
          <el-input :disabled="isEdit" v-model.trim="shopAddAssistantForm.name" :maxlength="20" clearable placeholder="请输入20字以内的活动名称"></el-input>
        </el-form-item>
        <el-form-item label="生效时间" prop="start_time">
          <el-date-picker
          clearable
          :picker-options="timeOptions"
          :disabled="isEdit"
            v-model="order_time_range"
            type="datetimerange"
            @change="updateTime"
            @focus="getNowTimeTen"
          :default-time="[newDates, '23:59:59']"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="参团人数" prop="number">
          <el-input placeholder="不超3个字符" :disabled="isEdit" v-model="shopAddAssistantForm.number" clearable>
            <template slot="append">人</template>
          </el-input>
        </el-form-item>
        <el-form-item label="成团时限" prop="limited_time">
          <el-input placeholder="不超3个字符" :disabled="isEdit" v-model="shopAddAssistantForm.limited_time" clearable>
            <template slot="append">小时</template>
          </el-input>
        </el-form-item>
        <el-form-item label="虚拟成团" style="margin-bottom:0px">
          <el-switch v-model="shopAddAssistantForm.virtual_group" active-value="OPEN" active-text="开启" :disabled="isEdit" inactive-value="CLOSE" inactive-text="关闭"></el-switch>
        </el-form-item>
        <p style="margin-top:0;margin-left:100px;font-size:12px">开启虚拟成团后，活动成团时限到期时人数未满的团，系统将会模拟匿名买家凑满人数，使该团成团，开启以提高成团率</p>
        <el-form-item label="是否收取运费" style="margin-bottom:0px">
          <el-switch v-model="shopAddAssistantForm.collect_freight" :disabled="isEdit" active-value="OPEN" active-text="收取" inactive-value="CLOSE" inactive-text="不收"></el-switch>
        </el-form-item>
        <p style="margin-top:0;margin-left:100px;font-size:12px">收取运费时，系统将按照商品的拼团价，以及商城后台所设置的运费规则向C端用户收取运费；否则不收取拼团商品运费。</p>
      </el-form>
    </x-dialog>
  </div>
</template>

<script>
import * as API_Auth from '@/api/auth'
import Storage from '@/utils/storage'
import EnTableLayout from "@/../ui-components/TableLayout/src/main";
import XDialog from "@/components/x-dialog/x-dialog";
import { $xDialog } from "@/components/x-dialog/dialog.proxy";
export default {
  name: 'groupBuyingCtivity',
  components: {
    EnTableLayout,
    XDialog,
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        NOT_STARTED_YET: '未开始',
        IN_PROGRESS: '进行中',
        CLOSED: '已关闭',
        EXPIRED: '已结束'
      };
      return statusMap[status];
    }
  },
  data() {
    return {
      timeOptions: {
        disabledDate: (time) => {
          return time.getTime() < Date.now() - 1 * 24 * 3600 * 1000;
        }
      },
      infoDialog: $xDialog.create({
        title: "查看活动",
        width: "40vw",
        showClose: true,
        displayFooterExtras: true,
        disableCancel: true,
        disableConfirm: true
      }),
      examinationDialog: $xDialog.create({
        title: "添加活动",
        width: "40vw",
        showClose: true,
        displayFooterExtras: true,
        beforeConfirm: this.submitGoodsAuditForm
      }),
      uuid: Storage.getItem('seller_uuid'),
      // 列表loading状态
      loading: false,
      // 列表参数
      params: {
        page_no: 1,
        page_size: 20,
        virtual_group: '',
        status: '',
        name:''
      },
      // 列表数据
      tableData: {},
      order_time_range: [],
      // 店员编辑表单
      shopAddAssistantForm: {
        name: '',
        start_time: '',
        limited_time: '',
        number: '',
        virtual_group: 'CLOSE',
        collect_freight: 'OPEN'
      },
      // 店员表单 规则
      shopAddistantRules: {
        name: [
          {
            required: true,
            message: '请输入20字以内的活动名称',
            trigger: 'blur'
          },
          {
            validator: async (rule, value, callback) => {
              const obj = { name: value, id: this.shopAddAssistantForm.id || '' };
              const res = await API_Auth.checkGroupName(obj)
              if (!res) callback(new Error('当前名称已存在'))
              else callback()
            }, trigger: 'blur'
          }
        ],
        start_time: [
          {
            required: true,
            message: '请选择起止时间',
            trigger: 'change'
          }
        ],
        limited_time: [
          {
            required: true,
            message: '请填写成团时限',
            trigger: 'blur'
          },
          {
            required: true,
            validator: (role, value, callBack) => {
              const regex = /^[1-9]\d{0,2}$/;
              if (!regex.test(value)) {
                callBack(new Error('请输入3位以内的正整数'))
              }else {
                callBack()
              }
            }
          }
        ],
        number: [
          {
            required: true,
            message: '请填写参团人数',
            trigger: 'blur'
          },
          {
            required: true,
            validator: (role, value, callBack) => {
              const regex = /^[1-9]\d{0,2}$/;
              if (!regex.test(value)) {
                callBack(new Error('请输入3位以内的正整数'))
              } else {
                callBack()
              }
            },
            trigger: 'blur'
          }
        ]
      },
      // 是否是编辑模式 默认不是
      isEdit: false,
      newDates:'',
    }
  },
  mounted() {
    this.GET_ShopAssistantList()
  },
  activated() {
    
  },
  methods: {
    /**获取当前时间后10分钟 */
    getNowTimeTen () {
      const now=new Date();     //1. js获取当前时间
      const min=now.getMinutes();  //2. 获取当前分钟
      now.setMinutes(min+10);  //3. 设置当前时间+10分钟：把当前分钟数+10后的值重新设置为date对象的分钟数
      const h = now.getHours() < 10 ? ('0' + now.getHours()) : now.getHours()
      const f = now.getMinutes() < 10 ? ('0' + now.getMinutes()) : now.getMinutes()
      const s = now.getSeconds() < 10 ? ('0' + now.getseconds()) : now.getSeconds()
      this.newDates = h + ":" + f + ":" + s;
    },
    updateTime () {
      if (
        this.order_time_range &&
        this.order_time_range.length
      ) {
        const date = new Date(this.order_time_range[0]).getTime()
        const today = new Date().getTime()
        if (date <= today){
          // 当选择的日期时分秒小于当前时间重置为当前时间
          this.order_time_range[0]=new Date()
        }
        //选中的开始时间延后
        console.log(this.order_time_range);
        
        if (typeof this.order_time_range[0] === "string") {
          this.shopAddAssistantForm.start_time =new Date(this.order_time_range[0]).getTime();
          this.shopAddAssistantForm.end_time =new Date(this.order_time_range[1]).getTime();
        } else {
          this.shopAddAssistantForm.start_time =this.order_time_range[0].getTime();
          this.shopAddAssistantForm.end_time =this.order_time_range[1].getTime();
        }
      } else {
        this.shopAddAssistantForm.start_time= ''
        this.shopAddAssistantForm.end_time = ''
      }
    },
    /** 表单提交 */
    submitGoodsAuditForm () {
      let { promise, resolve, reject } = Promise.withResolvers();
      this.$refs.shopAddAssistantForm.validate((valid) => {
        if (valid) {
          const params = this.shopAddAssistantForm
          API_Auth.addGroup(params).then(() => {
            this.$message.success('保存成功！')
            this.GET_ShopAssistantList()
            resolve(true)
          }).catch(() => {
            reject(false)
          })
        } else {
          this.$message.error('请完善表单信息！')
          reject(false)
        }
      });
      return promise
    },
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size
      this.GET_ShopAssistantList()
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page
      this.GET_ShopAssistantList()
    },
    /** 查看活动 */
    handleView (row) {
      this.shopAddAssistantForm = {}
      this.order_time_range=[]
      API_Auth.getGroupDetail(row.id).then((res) => {
        this.shopAddAssistantForm=res
        this.order_time_range=[new Date(res.start_time),new Date(res.end_time)]
        this.infoDialog.display();
        // 设置添加模式
        this.isEdit = true
      })
    },
    /** 添加活动 */
    handleAdd() {
      this.order_time_range= [];
      // 店员编辑表单
      this.shopAddAssistantForm= {
        name: '',
        start_time: '',
        limited_time: '',
        number: '',
        virtual_group: 'CLOSE',
        collect_freight: 'OPEN'
      },
      this.examinationDialog.display();
      this.examinationDialog.config.title ="添加活动";
      // 设置添加模式
      this.isEdit = false
    },
    /** 编辑活动 */
    handleEdit (row) {
      this.shopAddAssistantForm = {}
      this.order_time_range=[]
      API_Auth.getGroupDetail(row.id).then((res) => {
        this.shopAddAssistantForm=res
        this.order_time_range=[new Date(res.start_time),new Date(res.end_time)]
        this.examinationDialog.display();
        this.examinationDialog.config.title ="编辑活动";
        // 设置添加模式
        this.isEdit = false
      })
    },

    /** 关闭活动 */
    handleDeleteShopAssistant(index, row) {
      this.$confirm('确定要关闭该活动吗？', '提示', {type: 'warning'}).then(() => {
        API_Auth.changeGroupStatus(row.id,'CLOSED').then(() => {
          this.$message.success('关闭成功！')
          this.GET_ShopAssistantList()
        })
      }).catch(() => {
      })
    },
    /** 开启活动 */
    handleRecoveryShopAssistant(index, row) {
      this.$confirm('确定要开启该活动吗？', '提示', {type: 'warning'}).then(() => {
        API_Auth.changeGroupStatus(row.id,'NOT_STARTED_YET').then(() => {
          this.$message.success('开启成功！')
          this.GET_ShopAssistantList()
        })
      }).catch(() => {
      })
    },
    /** 删除活动 */
			handleDeleteGoods(row) {
				this.$confirm('确认删除该活动吗?', '提示', { type: 'warning' }).then(() => {
					API_Auth.deleteGroup(row.id).then(() => {
						this.GET_ShopAssistantList()
						this.$message.success('删除成功！')
					})
				})
    },
    /**跳转管理商品 */
    handleEditShopAssistant (row) {
      this.$router.push({
        name: 'groupBuyingGoods',
        query: {
          id: row.id
        }
      })
    },
    search () {
      this.params.page_no = 1
      this.GET_ShopAssistantList() 
    },
    /** 获取拼团活动列表 */
    GET_ShopAssistantList() {
      this.loading = true
      API_Auth.getGroupList(this.params).then(response => {
        this.loading = false
        this.tableData = response
      }).catch(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.face-image {
  display: block;
  width: 50px;
  height: 50px;
  margin: 0 auto;
}

/deep/ .el-dialog__body {
  padding: 10px 20px;
}

/deep/ .img-code {
  width: 80%;

  .el-input-group__append {
    padding: 0;
    margin: 0;

    img {
      display: block;
      height: 30px;
      cursor: pointer;
    }
  }
}
</style>
